export const isMicrosoftTeamsApp = () => {
  if (typeof window === 'undefined') return false;

  const isTeamsWebOrDesktop = ['embedded-page-container', 'extension-tab-frame'].includes(window.name);
  const isTeamsMobile = /TeamsMobile/i.test(navigator.userAgent);

  return isTeamsWebOrDesktop || isTeamsMobile;
};

export const isMicrosoftTeamsAppMobile = () => {
  return /TeamsMobile/i.test(navigator.userAgent);
};

export const microsoftTeamsBasePath = process.env.NEXT_PUBLIC_MS_TEAMS_BASE_PATH;

export const redirectToMicrosoftTeamsApp = (router) => {
  if (isMicrosoftTeamsApp()) {
    router.push(microsoftTeamsBasePath);
  }
};

export const isMeetingContext = (context) => (context?.match(/^(meetingStage|sidePanel)$/));

export const isContent = context => context === 'content';
